import React, { Fragment, Suspense, useLayoutEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

/*
import Index from '../pages';
import About from '../pages/about';
import Images from '../pages/images';
import Certificates from '../pages/certificates';
import Contact from '../pages/contact';
import Products from '../pages/products';
import Catalog from '../pages/catalog'; 
*/
const Index = React.lazy(() => import("../pages"));
const About = React.lazy(() => import("../pages/about"));
const Images = React.lazy(() => import("../pages/images"));
const Certificates = React.lazy(() => import("../pages/certificates"));
const Contact = React.lazy(() => import("../pages/contact"));
const Products = React.lazy(() => import("../pages/products"));
const Catalog = React.lazy(() => import("../pages/catalog"));

const Router = () => {
    const Wrapper = ({ children }) => {
        const location = useLocation();
        useLayoutEffect(() => {
            document.documentElement.scrollTo(0, 0);
        }, [location.pathname]);
        return children
    }
    return (
        <BrowserRouter>
            <Wrapper>
                <Fragment>
                    <Suspense fallback={
                        < div style={{ width: "100%", height: "100vh", backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img src='/assets/img/logo.svg' height={75} alt='' />
                            <p style={{ fontSize: "1rem", opacity: ".5" }}>Yükleniyor...</p>
                        </div>
                    }>
                        <Routes>
                            <Route path="/" element={<Index />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/images" element={<Images />} />
                            <Route path="/products" element={<Products />} />
                            <Route path="/certificates" element={<Certificates />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/catalog" element={<Catalog />} />
                            <Route path="*" element={<Index />} />
                        </Routes>
                    </Suspense>
                </Fragment>
            </Wrapper>
        </BrowserRouter >
    )
}

export default Router;